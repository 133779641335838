.boxTitle-3AqybBsR {
  color: var(--primary-text-color);
  max-width: 80%;
  margin: 0 auto 50px;
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
}

.theme-promo-sunrise .boxTitle-3AqybBsR {
  color: #e6003c;
}

.modalCloseIcon-3UnUepIB {
  position: relative;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-text-color);
  background-color: var(--primary-background-color);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 0;
  margin-top: 40px;
  cursor: pointer;
}

.modalCloseIcon-3UnUepIB:hover {
  border: 1px solid var(--primary-text-color);
  background-color: var(--primary-text-color);
}

.modalCloseIconImage-1qVcAFuW {
  stroke: var(--secondary-text-color);
}

.modal.magic-link-sent .modalCloseIcon-3UnUepIB {
  bottom: 75px;
}

.modalCloseIcon-3UnUepIB:hover .modalCloseIconImage-1qVcAFuW {
  stroke: var(--secondary-background-color);
}
.theme-promo-sunrise .modalCloseIcon-3UnUepIB:hover {
  background-color: #e6003c;
  border-color: #e6003c;
}

@media(max-width: 760px) {
  .boxTitle-3AqybBsR {
    font-size: 24px;
    line-height: 1.25;
  }
}
