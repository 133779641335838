.input-text-autocomplete-V61VacsV {
  position: relative;
  width: 100%;
}

.input-text-autocomplete-V61VacsV input[type='text'] {
  display: block;
  width: 100%;
  height: 25px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-bottom-width: 1px;
  font-size: 18px;
  font-weight: normal;
  color: var(--primary-text-color);
  background: transparent;
}

.shop-checkout .input-text-autocomplete-V61VacsV input[type='text'] {
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  color: #000000;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-width: 1px;
  height: 25px;
  transition: all .4s;
}

.shop-checkout .input-text-autocomplete-V61VacsV input[type='text']:focus,
.input-text-autocomplete-V61VacsV input[type='text']:focus {
  border-color: var(--highlight-color);
}

.checkout-container-new .input-text-autocomplete-V61VacsV input[type='text'] {
  border-radius: 0;
}

.shop-checkout .input-text-autocomplete-hasError-IL-tZMv0 input[type='text'],
.shop-checkout .input-text-autocomplete-hasError-IL-tZMv0 input[type='text']:focus,
.input-text-autocomplete-hasError-IL-tZMv0 input[type='text'],
.input-text-autocomplete-hasError-IL-tZMv0 input[type='text']:focus {
  border-color: var(--color-error)
}

.shop-checkout .input-text-autocomplete-Success-3KL9_zeH input[type='text'],
.input-text-autocomplete-Success-3KL9_zeH input[type='text'] {
  border-color: var(--color-success);
}

.input-text-autocomplete-clear-1pBTYBim {
  position: absolute;
  right: 5px;
  bottom: 10px;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  background: transparent;
}

.input-text-autocomplete-hasError-IL-tZMv0 .input-text-autocomplete-clear-1pBTYBim {
  bottom: 30px;
}

.input-text-autocomplete-clear-1pBTYBim svg {
  width: 15px;
  height: 15px;
}

.input-text-autocomplete-label-lSY-mHtN {
  width: 100%;
  display: block;
}

.input-text-autocomplete-label-title-2ejD64ej {
  padding-left: 0;
  color: var(--secondary-text-color-s);
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.shop-checkout .input-text-autocomplete-label-title-2ejD64ej {
  padding-left: 0;
  color: var(--secondary-text-color-s);
  position: relative;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.input-text-autocomplete-options-343dUjNQ {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  background: var(--primary-background-color);
  border: 1px solid var(--input-border-color);
  box-shadow: 0 3px 5px rgba(0,0,0,0.05);
  z-index: 2;
  width: 100%;
}

.input-text-autocomplete-options-active-hO3W5urO {
  display: block;
}

.input-text-autocomplete-option-2o6HLHeF {
  border-top: 1px solid var(--input-border-color);
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  width: 100%;
  white-space: nowrap;
  color: var(--primary-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-text-autocomplete-option-2o6HLHeF:first-child {
  border-top: 0;
}

.input-text-autocomplete-option-2o6HLHeF:hover {
  cursor: pointer;
  background-color: var(--ui-light-gray);
}

.highlight-2zmpTZkn {
  background-color: var(--ui-light-gray);
}

.input-text-autocomplete-label-title-2ejD64ej:after {
  content: attr(data-content);
  text-transform: none;
}

.input-text-autocomplete-required-32r9eepj .input-text-autocomplete-label-title-2ejD64ej:after {
  content: '';
}

@media (min-width: 993px) {
  .input-text-autocomplete-V61VacsV input[type='text'] {
    padding-left: 6px;
  }
  }
@media(max-width: 992px) {
  .shop-checkout .input-text-autocomplete-label-title-2ejD64ej {
    font-weight: normal;
    font-size: 10px;
  }
}
