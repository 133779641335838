.paymentMethodsContent-3a-NTfVH {
  margin: 0 auto;
  width: var(--content-width);
}

.notDefault-qV9KSmK3 {
  padding-top: 26px;
}

.cardFadeOut-26-vNOqD {
  opacity: 0;
}

.card-3uNLwrT8 {
  height: 100%;
  position: relative;
  padding: 22px 30px 30px 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid var(--secondary-text-color);
  background-color: var(--secondary-background-color);
  width: 49%;
  cursor: pointer;
}


@media screen and (max-width: 759px) {
  .card-3uNLwrT8 {
    width: 100%;
  }
}

.card-3uNLwrT8::after {
  content: "";
  display: block;
  position: absolute;
  top: 24px;
  left: 22px;
  height: 16px;
  width: 16px;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #cdcdcd;
  background-color: var(--secondary-background-color);
  border-image: initial;
  cursor: pointer;
}

.card-active-tQNVFaW7::after {
  border: solid 4px var(--highlight-color);
}

.theme-promo-sunrise .card-active-tQNVFaW7::after {
  border: solid 4px #e6003c;
}

.card-active-tQNVFaW7 {
  border: 1px solid #191941;
}

.input-error-3c5gKYJJ {
  border-bottom-color: var(--color-error);
}

.card-text-1q7dZ9yn {
  color: var(--primary-text-color);
}

.card-badge-3gPwzssY {
  position: absolute;
  top: 10px;
  right: 35px;
  width: auto;
  height: 24px;
  margin: 10px 0 8px;
}

.card-cvv-3fkMCjpt {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 15px;
}

.card-cvv-3fkMCjpt input[type="text"] {
  display: block;
  width: 100%;
  height: 40px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  font-size: 16px;
  font-weight: 500;
  color: #3c396f;
  background-color: transparent;
  border-image: initial;
  border-radius: 0;
  padding: 2px 0;
}
