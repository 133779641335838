.creditCard-2_omfpJS {
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
  border-radius: 10px;
  border: solid 1px #c6c6c6;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 30px 15px 30px;
  height: 150px;
  position: relative;
  min-width: 300px;
  margin-top: 20px;
}

.customWidth-39E_r-TF {
  max-width: 200px;
}

.dashboardAlert-1Wfd7fbh {
  position: absolute;
  top: -46px;
  left: 0;
  min-width: 228px;
  width: 100%;
}

.dashboardAlertSunrise-nkAH6g_6 {
  top: -56px;
}

.creditCard-2_omfpJS.default-11B0gige {
  border: solid 2px #191941;
}

.cardInfoText-2Wb9LKcR {
  font-size: 18px;
  font-weight: 500;
  color: #3c396f;
  line-height: 1.4;
}

.isExpired-1zclu3xH .cardInfoText-2Wb9LKcR{
  color: #fa1478;
}

.cardBadge-1lerQ2Wi {
  width: auto;
  height: 24px;
}

.defaultLabel-YsaWnnWY {
  font-size: 14px;
  color: #939597;
}

.action-2l9UnENw {
  font-size: 14px;
  color: #6269ff;
  cursor: pointer;
}

.delete-3DigQZEu {
  font-size: 14px;
  color: #fa1478;
  margin-left: auto;
  cursor: pointer;
}

.cardInfo-OTkHlP2c {
  display: flex;
  justify-content: space-between;
}

.footer-ll7cPK2O {
  display: flex;
  justify-content: space-between;
}

@media(max-width: 375px) {
  .dashboardAlertSunrise-nkAH6g_6 {
    top: -56px;
    left: 0;
  }
}

@media (max-width: 760px) {
  .creditCard-2_omfpJS {
    width: 100%;
    margin-right: 0;
    height: 180px;
  }
  .dashboardAlert-1Wfd7fbh {
    top: -39px;
  }
}

@media (max-width: 768px) {
  .cardInfoText-2Wb9LKcR {
    font-size: 14px;
  }
}
