.zuoraPayment-3b_bUCVI {
  min-height: 274px;
}

.zuora_payment_sepa {
  min-height: 277px !important;
  overflow-y: scroll;
  height: 277px !important;
}

.zuora_payment_sepa iframe {
  background-color: transparent;
  width: 100%;
  min-height: 1500px !important;
}

#zuora_payment iframe {
  min-height: 490px;
}

.input-checkbox-2PxfjPna {
  overflow: hidden;
  position: relative;
  height: 20px;
  line-height: 22px;
  margin-bottom: 20px;
}

.input-checkbox-2PxfjPna input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

.input-checkbox-2PxfjPna input[type='checkbox']:disabled + .input-checkbox-checkmark-2SDzPl8N {
  opacity: 0.2;
}

.input-checkbox-checkmark-2SDzPl8N {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid 2px var(--secondary-text-color);
  border-radius: 2px;
  background-color: var(--primary-backgroud-color);
  margin: 0 8px 0 0;
  vertical-align: top;
  cursor: pointer;
}

.input-checkbox-label-Xkh6auwI {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: var(--secondary-text-color);
  cursor: pointer;
  user-select: none;
}

.input-checkbox-checkmark-active-3cPOT6mI {
  background-color: var(--tertiary-text-color);
  border: solid 2px var(--tertiary-text-color);
}

.checkmarkIcon-iXU8jrk8 {
  height: 1em;
  width: auto;
}
