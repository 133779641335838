.box-1TDJjTs- {
  padding: 20px 30px;
  border: 1px solid #DEE0E2;
  width: 400px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity .1s;
  flex-shrink: 0;
}

.error-L00Y8lMW {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.box-1TDJjTs- label {}
.box-1TDJjTs- label input {
  display: none;
}
.box-1TDJjTs- label input + span {
  display: block;
  width: 51px;
  height: 31px;
  border-radius: 20px;
  border: 1px solid #E5E5E5;
  position: relative;
  cursor: pointer;
  transition: all .2s;
}
.box-1TDJjTs- label input + span:after {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  left: 1px;
  top: 1px;
  background: #FFFFFF;
  border-radius: 100%;
  border: 0.25px solid rgba(0, 0, 0, 0.101987);
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 8px rgba(0, 0, 0, 0.15);
  transition: all .2s;
}
.box-1TDJjTs- label input:checked + span {
  background: #6269FF;
}
.box-1TDJjTs- label input:checked + span:after {
  transform: translate(20px,0);
}

.status-2_gxa7Z2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: var(--primary-text-color-dark);
  margin: 0 8px;
}

.theme-promo-sunrise .status-2_gxa7Z2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.termsLink-1mKrM7oL {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #6269FF;
  border: none;
  background: none;
  cursor: pointer;
}

.loading-1vG0W2Fu {
  opacity: 0.5;
  pointer-events: none;
}

.modalContent-3HLv-iNq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.termsContainer-1T2k4Vz- p {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  margin-bottom: 22px;
  letter-spacing: normal;
  color: var(--secondary-text-color-s);
}

.confirmationContainer-Oq7OTQ6- p {
  font-family: var(--primary-font-family);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-bottom: 22px;
  letter-spacing: normal;
  color: var(--secondary-text-color-s);
  max-width: 518px;
  width: 100%;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.buttonContainer-piHzvjhW {
  width: 100%;
  max-width: 300px;
  height: 50px;
}

.disabled-nRahly8I {
  pointer-events: none;
  opacity: 0.4;
}

@media screen and (max-width: 720px) {
  .box-1TDJjTs- {
    padding: 5px 5px;
    width: auto;
    flex-direction: column;
    min-width: 120px;
  }

  .status-2_gxa7Z2 {
    font-size: 12px;
  }

  .termsLink-1mKrM7oL {
    font-size: 16px;
  }

  .termsContainer-1T2k4Vz- {
    flex-direction: column;
  }

  .termsBlock-1l7qNW1z {
    margin: 0;
  }

  .termsBlock-1l7qNW1z:first-child {
    margin-bottom: 30px;
  }
}

@media(max-width: 375px) {
  .termsLink-1mKrM7oL {
    font-size: 12px;
  }
}

.title-al2p5HNo {
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: var(--primary-text-color);
  margin-bottom: 40px;
}
