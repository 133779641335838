.btnContainer-1AqjlU-R {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.btnContainer-1AqjlU-R button:hover {
  cursor: pointer;
}

.isDisabled-2bDjohb2 {
  display: flex;
  background-color: #DEE0E2;
  border: 1px solid #DEE0E2;
  color: var(--button-dark-background-color);
  justify-content: center;
  line-height: 1.1;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  font-family: var(--primary-font-family);
  width: 100%;
  font-size: 16px;
  padding: 10px 0 10px;
  max-width: 180px;
  z-index: 0;
}

.isActive-2pFBhG9- {
  display: flex;
  color: var(--button-dark-text-color);
  background-color: var(--button-dark-background-color);
  border-color: var(--button-dark-background-color);
  justify-content: center;
  line-height: 1.1;
  border-radius: 25px;
  border: 1px solid;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  font-family: var(--primary-font-family);
  width: 100%;
  font-size: 16px;
  padding: 10px 0 10px;
  max-width: 152px;
  z-index: 1;
}
.isDisabled-2bDjohb2:first-child {
  margin-right: -28px;
}

.isDisabled-2bDjohb2:first-child span {
  margin-right: 20px;
}

.isDisabled-2bDjohb2:last-child {
  margin-left: -28px;
}

.isDisabled-2bDjohb2:last-child span {
  margin-left: 20px;
}


.isActive-2pFBhG9-:hover,
.isActive-2pFBhG9-:focus,
.isActive-2pFBhG9-:active {
  opacity: .9;
}

.isActive-2pFBhG9-:disabled {
  background-color: var(--button-dark-background-disabled-color);
  border-color: var(--button-dark-background-disabled-color);
  cursor: default;
}

.paymentMethodsContainer-v0dNeQ-b {
  position: relative;
}

.spinner-GNviNz10 {
  position: absolute;
  top: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 100%;
}
