.block-2gmt3SX4 {
  margin-top: 20px;
  margin-bottom: 50px;
}

.titleLine-f12FVTqj {
  background: var(--secondary-background-color);
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.titleLine-f12FVTqj h3 {
  font-weight: 500;
  font-family: var(--primary-font-family);
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.5px;
  color: var(--new-secondary-text-color);
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #fff;
  z-index: 2;
  position: relative;
  line-height: 24px;
}

.titleLine-f12FVTqj:after {
  content: '';
  width: auto;
  position: absolute;
  left: 0;
  right: 200px;
  top: 50%;
  height: 1px;
  background-color: var(--sedondary-underline-color);
}

.action-36J7mGv5 {
  border: 1px solid var(--tertiary-text-color);
  box-sizing: border-box;
  box-shadow: -2px 1px 6px rgba(98, 105, 255, 0.2);
  padding: 10px 15px;
  cursor: pointer;
  width: 140px;
  height: 38px;
  border-radius: 25px;
  color: var(--tertiary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-36J7mGv5:hover {
  color: white !important;
  background-color: var(--tertiary-text-color);
  transition: all .2s;
}

.theme-promo-sunrise .action-36J7mGv5 {
  position: relative;
  top: -2px;
  border: 1px solid var(--sunrise-primary-text-color);
  box-shadow: -2px 1px 6px rgba(230, 0, 60, 0.2);
}

.theme-promo-sunrise .action-36J7mGv5:hover {
  color: white !important;
  background-color: var(--sunrise-primary-text-color);
  transition: all .2s;
}



.with-scroll-22RlRp8E {
  overflow-y: auto;
  max-height: 500px;
  height: 100%;
  padding: 20px 20px 20px 0;
}

.theme-promo-sunrise .action-36J7mGv5 span {
  position: relative;
  top: -2px;
}
