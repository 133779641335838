.modalOverlay-P9W9ZhS2 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background: var(--dim-background-color);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: opacity ease-in .25s;
}

.modalOverlay-P9W9ZhS2.modalActive-3Q-O9yjE {
  opacity: 1;
  pointer-events: all;
}

.modal-3lLCJWQe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 760px;
  max-width: 96%;
  max-height: 96%;
  border-radius: 20px;
  background-color: var(--primary-background-color);
  box-shadow: 0 10px 40px 0 rgba(60, 57, 111, 0.2);
  padding: 40px 50px 40px 50px;
  transform: translate(-50%,-50%);
  pointer-events: none;
  overflow-y: auto;
  opacity: 0;
  transition: opacity .3s;
}

@media screen and (max-width: 992px) {
  .modal-3lLCJWQe {
    padding: 30px 30px 120px;
  }
}

@media screen and (max-width: 760px) {
  .modal-3lLCJWQe {
    padding: 20px;
  }
}

.modalActive-3Q-O9yjE {
  opacity: 1;
  pointer-events: all;
}

.modalTitle-1Ocm_fqT {
  color: var(--primary-text-color);
  max-width: 80%;
  margin: 0 auto 50px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .modalTitle-1Ocm_fqT {
    max-width: 90%;
  }
}

.modalCloseIcon-2x8LbsVi {
  position: relative;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-text-color);
  background-color: var(--primary-background-color);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 0;
  margin-top: 40px;
  cursor: pointer;
}

.modalCloseIcon-2x8LbsVi:hover {
  border: 1px solid var(--primary-text-color);
  background-color: var(--primary-text-color);
}

.modalCloseIconImage-393CFBZk {
  stroke: var(--secondary-text-color);
}

.modal.magic-link-sent .modalCloseIcon-2x8LbsVi {
  bottom: 75px;
}

.modalCloseIcon-2x8LbsVi:hover .modalCloseIconImage-393CFBZk {
  stroke: var(--secondary-background-color);
}
.theme-promo-sunrise .modalCloseIcon-2x8LbsVi:hover {
  background-color: #e6003c;
  border-color: #e6003c;
}
.theme-promo-sunrise .modalTitle-1Ocm_fqT {
  color: #e6003c;
}
