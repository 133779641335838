.renew-membership--wKnBtNl {
  background: var(--color-error-bg);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.renew-membership--wKnBtNl p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.9px;
  color: var(--primary-text-color);
}

.renew-membership-link-1PQ45Gpv {
  font-size: 18px;
  color: var(--highlight-color);
  margin-top: 5px;
  cursor: pointer;
}

.payment-methods-wrapper-2FRIyhVQ {
  margin: 20px 0 10px;
}

.renew-container-1a7JTjUI {
  position: relative;
}

.renew-info-3zL5mU_q {
  border-radius: 10px;
  background-color: #f5f5f7;
  padding: 12px 20px;
  color: var(--secondary-text-color);
}

.renew-info-highlight-feDzMfgG {
  background-color: rgba(98, 105, 255, .1);
  color: #3c396f;
  font-weight: bold;
}

.theme-promo-sunrise .renew-info-highlight-feDzMfgG {
  background: var(--color-error-bg);
}

.input-checkbox-13h2CAEq {
  overflow: hidden;
  position: relative;
  line-height: 22px;
  margin-bottom: 20px;
}

.input-checkbox-13h2CAEq input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

.input-checkbox-13h2CAEq input[type='checkbox']:disabled + .input-checkbox-checkmark-3GTtYwDS {
  opacity: 0.2;
}

.input-checkbox-checkmark-3GTtYwDS {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid 2px var(--secondary-text-color);
  border-radius: 2px;
  background-color: var(--primary-backgroud-color);
  margin: 2px 8px 0 0;
  vertical-align: top;
  cursor: pointer;
}

.input-checkbox-label-14Wjtq9F {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-text-color);
  user-select: none;
  width: 95%;
}

.theme-promo-sunrise .input-checkbox-label-14Wjtq9F a {
  color: #e6003c;
}

@media screen and (max-width: 760px) {
  .input-checkbox-label-14Wjtq9F {
    width: 90%;
  }
}

.input-checkbox-checkmark-active-18HUZOzI {
  background-color: var(--tertiary-text-color);
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-3GTtYwDS:hover {
  border: solid 2px #e6003c;
}

.theme-promo-sunrise .input-checkbox-checkmark-active-18HUZOzI {
  background-color: #e6003c;
  border: solid 2px #e6003c;
}

.theme-promo-sunrise .input-checkbox-checkmark-active-18HUZOzI svg rect {
  fill: #e6003c;
}

.checkmarkIcon-yhniTtQR {
  height: 1em;
  width: auto;
  position: relative;
  top: -2px;
}

.add-new-method-2PbLdgNq {
  font-size: 15px;
  color: var(--tertiary-text-color);
  cursor: pointer;
}

.theme-promo-sunrise .add-new-method-2PbLdgNq {
  color: #e6003c;
}

.is-validating-1worH-xS {
  opacity: .3;
  pointer-events: none;
  position: relative;
}

.overlay-spinner-2cnlaVcp {
  position: absolute;
  top: 100px;
  z-index: 400;
}

.check-terms-2zINzyC4 {
  margin-top: 28px;
  margin-bottom: 16px;
}

.renew-button-EFQGCoRD {
  padding: 0;
  display: flex;
  justify-content: center;
}

.options-3eiolloJ {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0;
}

.option-344SG6iU {
  height: 100%;
  position: relative;
  padding: 22px 30px 20px 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid var(--color-white);
  background-color: var(--secondary-background-color);
  width: 49%;
  cursor: pointer;
}

.option-344SG6iU.selected-CUSWODAW{
  border: 1px solid var(--secondary-text-color);
  background-color: var(--secondary-background-color);
}

.option-344SG6iU .label-2y_QPB87 {
  font-size: 17px;
  font-weight: 500;
  color: var(--secondary-text-color);
  margin-bottom: 15px;
}

.option-344SG6iU .price-2TkrSn2_ {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-text-color);
}
