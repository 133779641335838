.input-select-3E3CVCEH {
  position: relative;
}

.input-select-3E3CVCEH select {
  display: block;
  width: 100%;
  height: 25px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-bottom-width: 1px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  border-radius: 0;
  -webkit-appearance: none;
  z-index: 1;
  position: relative;
}

.shop-checkout .input-select-3E3CVCEH select {
  height: 25px;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-width: 1px;
  font-size: 17px;
  font-weight: normal;
  padding-left: 8px;
  transition: all .4s;
  background: transparent;
}

.shop-checkout .input-select-3E3CVCEH select:focus,
.input-select-3E3CVCEH select:focus {
  border-color: var(--highlight-color);
}

.input-select-3E3CVCEH select option {
  font-size: 16px;
  font-weight: 500;
}

.shop-checkout .input-select-hasError-24RxMc2K select,
.shop-checkout .input-select-hasError-24RxMc2K select:focus,
.input-select-hasError-24RxMc2K select,
.input-select-hasError-24RxMc2K select:focus {
  border-color: var(--color-error);
}

.shop-checkout .input-select-Success-3spKBO9B select,
.shop-checkout .input-select-Success-3spKBO9B select:focus,
.input-select-Success-3spKBO9B select,
.input-select-Success-3spKBO9B select:focus {
  border-color: var(--color-success)
}

.input-select-label-1P4lwkHT {
  padding-left: 0;
  color: var(--secondary-text-color-s);
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.shop-checkout .input-select-label-1P4lwkHT {
padding-left: 0;
color: var(--secondary-text-color-s);
position: relative;
font-weight: normal;
font-size: 11px;
line-height: 17px;
letter-spacing: 0.11em;
text-transform: uppercase;
margin-bottom: 5px;
}

.input-select-arrow-UxqDRvJi {
position: absolute;
right: 5px;
top: 25px;
z-index: 0;
}

.input-select-arrow-UxqDRvJi svg {
width: 20px;
}

.shop-checkout .input-select-arrow-UxqDRvJi svg {
width: 15px;
}

.input-select-error-2Yd254fG {
  position: absolute;
  margin-top: 6px;
  color: var(--color-error);
  font-size: 12px;
}

.shop-checkout .input-select-error-2Yd254fG{
font-size: 10px;
display: inline-block;
margin-top: 7px;
}

.input-select-label-1P4lwkHT:after {
content: ' (optional)';
text-transform: none;
}

.input-select-required-1em5E6iq .input-select-label-1P4lwkHT:after {
content: '';
}

@media(max-width: 992px) {
  .shop-checkout .input-select-label-1P4lwkHT {
    font-weight: normal;
    font-size: 10px;
  }
}
