.container-j3BdwS8X {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.15s;
  z-index: 2000;
  max-width: 96%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
}

.overlay-3i8JK2Dr {
  background: var(--dim-background-color);
  position: fixed;
  z-index: 1040;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 0;
}

.fadeIn-2xh1pZqe {
  opacity: 1;
  transition: opacity linear 0.15s;
}

.fadeOut-3xbZEeWH{
  opacity: 0;
  transition: opacity linear 0.15s;
}



.boxDialog-3SQ0gUGY {
  z-index: 1050;
  background-color: var(--primary-background-color);
  box-shadow: 0 10px 40px 0 rgba(60, 57, 111, 0.2);
  border-radius: 20px;
  padding: 40px 50px 40px 50px;
  margin: 40px auto;
  width: 760px;
  max-width: 96%;
  max-height: 96%;
  overflow-y: auto;
}

.boxContent-1bOlEAvP {
  width: 100%;
  position: relative;
}

.boxHeader-1P6db8su {
  height: 48px;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c7c7c7;
}


.close-1BDToYPd {
  font-size: 35px;
  line-height: 35px;
  font-weight: 400;
  text-shadow: none;
  color: black;
  cursor: pointer;
}

.close-1BDToYPd:hover {
  opacity: 0.5;
}

.boxBody-PMsZDExu {
  font-size: 14px;
  padding: 0px;
  width: auto;
  height: auto;
}

.boxFooter-2x6wSG5m {
  height: 48px;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #c7c7c7;
}

@media(max-width: 760px) {
  .boxDialog-3SQ0gUGY {
    padding: 20px;
  }
}
