.error-overlay-39C95jLb {
  position: absolute;
  background-color: rgba(255,255,255,.3);
  width: 100%;
  height: calc(100% - 60px);
  z-index: 999;
}

.error-overlay-box-2M0TJ1Fv {
  position: relative;
  background-color: var(--secondary-background-color);
  box-shadow: rgba(60, 57, 111, 0.2) 0px 10px 40px 0px;
  width: 400px;
  min-height: 280px;
  border-radius: 20px;
  padding: 60px 36px;
  margin: 50px auto;
}

@media(max-width: 760px) {
  .error-overlay-box-2M0TJ1Fv {
    max-width: 320px;
    min-height: 280px;
  }
}

.error-overlay-message-1YyTG3v- {
  color: var(--primary-text-color);
  text-align: center;
}

.error-overlay-btn-14HstprX {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-text-color);
  background-color: var(--secondary-background-color);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
}

.error-overlay-btn-14HstprX:hover {
  border: 1px solid var(--primary-text-color);
  background-color: var(--primary-text-color);
}

.error-overlay-close-icon-3h0PPuu1 {
  stroke: var(--secondary-text-color);
}

.error-overlay-btn-14HstprX:hover .error-overlay-close-icon-3h0PPuu1 {
  stroke: var(--secondary-background-color);
}
