.input-text-2R3m2csB {
  position: relative;
}

.theme-promo-sunrise.checkout-page .input-text-2R3m2csB.input-text-required-2vwtEcjz{
  padding-bottom: 28px;
}

.input-text-2R3m2csB input[type='text'] {
  display: block;
  width: 100%;
  height: 30px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-bottom-width: 1px;
  font-size: 18px;
  font-weight: normal;
  color: var(--primary-text-color);
  background-color: transparent;
  padding-left: 8px;
}
.checkout-container-new .input-text-2R3m2csB input[type='text'] {
  border-radius: 0;
}

.shop-checkout .checkout-container-new .input-text-2R3m2csB input[type='text'] {
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  color: #000000;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-width: 1px;
  height: 25px;
  transition: all .4s;
}

.input-text-2R3m2csB input[readonly] {
  cursor: default;
}

.shop-checkout .checkout-container-new .input-text-2R3m2csB input[type='text']:focus,
.input-text-2R3m2csB input[type='text']:focus {
  border-color: var(--highlight-color);
}

.shop-checkout .checkout-container-new .input-text-hasError-9IKRph1a input[type='text'],
.shop-checkout .checkout-container-new .input-text-hasError-9IKRph1a input[type='text']:focus,
.input-text-hasError-9IKRph1a input[type='text'],
.input-text-hasError-9IKRph1a input[type='text']:focus {
  border-color: var(--color-error);
}

.shop-checkout .checkout-container-new .input-text-Success-EQczoHtS input[type='text'],
.shop-checkout .checkout-container-new .input-text-Success-EQczoHtS input[type='text']:focus,
.input-text-Success-EQczoHtS input[type='text'],
.input-text-Success-EQczoHtS input[type='text']:focus {
  border-color: var(--color-success);
}

.input-text-label-1Ytwo7PZ {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1px;
  color: var(--secondary-text-color-s);
  display: block;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.shop-checkout .checkout-container-new .input-text-label-1Ytwo7PZ {
  padding-left: 0;
  color: var(--secondary-text-color-s);
  position: relative;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.shop-checkout .checkout-container-new .input-text-label-1Ytwo7PZ:after {
  /*position: absolute;*/
}

.input-text-success-2kCMbHcI {
  color: var(--color-success);
  font-size: 12px;
}

.input-text-error-1sgDl3Eu {
  position: absolute;
  margin-top: 6px;
  color: var(--color-error);
  font-size: 12px;
}

.shop-checkout .input-text-error-1sgDl3Eu {
  position: absolute;
  bottom: -16px;
  width: 235px;
  font-size: 10px;
}

.input-text-label-1Ytwo7PZ:after {
  content: attr(data-content);
  text-transform: none;
}

.input-text-required-2vwtEcjz .input-text-label-1Ytwo7PZ:after {
  content: '';
}

.input-text-2R3m2csB label {
  position: relative;
  display: block;
}

.lowerCase-3Pb_ak5I {
  text-transform: lowercase;
}

@media (max-width: 992px) {
  .input-text-label-1Ytwo7PZ, .input-text-2R3m2csB input[type='text'] {
    padding-left: 0;
  }
  .shop-checkout .checkout-container-new .input-text-label-1Ytwo7PZ {
    font-weight: normal;
    font-size: 10px;
  }
}

@media(max-width:600px) {
 #shippingPostalCode__errorMessage {
    position: absolute;
    width: 104px;
    bottom: -37px;
    font-size: 10px;
  }
}
